/* Styling for App */

@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


html {
  font-size: 62.5%;
  background: rgb(245 253 252);
}

body.no-scroll {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none; 
  overflow: hidden;
}

p, h1, h2, h3, h4, h5, h6, ul, li, ol {
  margin: 0;
}

a {
  display: flex;
  text-decoration: none;
  color: inherit;
}


// Slick dots styling
.slick-dots {
  position: relative !important;
  margin-top: 30px !important;
  bottom: 10px !important; // Adjust position
  display: flex !important; // Force it to flex
  justify-content: center !important;
  
  li {
      margin: 0 5px !important; // Adjust spacing between dots

      button {
          &:before {
              font-size: .8rem !important;
              color: #4b4b4b !important; // Dot color
              opacity: 0.5 !important; // Inactive state
          }
      }

      &.slick-active {
          button {
              &:before {
                font-size: 1rem !important;
                  color: #00bfa5 !important; // Active dot color
                  opacity: 1 !important; // Fully visible for active dot
              }
          }
      }
  }
}

//Nav Menu Styles
.click {
  align-items: center;
  display: inline-flex;
  gap: 1rem;
  justify-content: center;
  padding: 1rem 1.5rem;
  position: relative;
}

.click .nav-text {
  color:#0c1422;
  font-family: "Roboto", Helvetica;
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  text-decoration: none;
}

.click .nav-text.active {
  color: #00bfa5; /* Change this to your desired active link color */
  font-weight: bold; /* Optional: make the active link bold */
}

@media screen and (max-width: 1340px) {
  .click {
    padding: 1.2rem 1.5rem;
  }

  .click .nav-text {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {

  .slick-dots {
    position: relative !important;
    margin-top: 15px !important;
    bottom: 10px !important; // Adjust position
    display: flex !important; // Force it to flex
    justify-content: center !important;
  }

  .click {
    padding: 0;
    width: 100%;
    
  }

  .click .nav-text {
    font-size: 1.6rem;
    width: 100%;
    align-self: stretch;
    padding: 1.2rem 1.2rem 1.2rem 0rem;
  }

  a {
    width: 100%;
  }
}



:root {
  --accent-2: rgba(189, 189, 189, 1);
  --accent-colour: rgba(253, 126, 20, 1);
  --accent-colour-1: rgba(212, 232, 21, 0.47);
  --article-image-1: rgba(196, 196, 196, 1);
  --article-image-2: rgba(196, 196, 196, 1);
  --article-image-3: rgba(196, 196, 196, 1);
  --black: rgba(30, 30, 30, 1);
  --black-and-whitewhite: rgba(255, 255, 255, 1);
  --black-whitewhite: rgba(255, 255, 255, 1);
  --body-caption-regular-font-family: "Aeonik", Helvetica;
  --body-caption-regular-font-size: 14px;
  --body-caption-regular-font-style: normal;
  --body-caption-regular-font-weight: 400;
  --body-caption-regular-letter-spacing: 0px;
  --body-caption-regular-line-height: 21px;
  --body-text-20-bold-font-family: "Roboto", Helvetica;
  --body-text-20-bold-font-size: 20px;
  --body-text-20-bold-font-style: normal;
  --body-text-20-bold-font-weight: 700;
  --body-text-20-bold-letter-spacing: 0.10000000149011612px;
  --body-text-20-bold-line-height: 30px;
  --body-text-20-medium-font-family: "Roboto", Helvetica;
  --body-text-20-medium-font-size: 20px;
  --body-text-20-medium-font-style: normal;
  --body-text-20-medium-font-weight: 500;
  --body-text-20-medium-letter-spacing: 0px;
  --body-text-20-medium-line-height: 30px;
  --body-text-20-regular-font-family: "Roboto", Helvetica;
  --body-text-20-regular-font-size: 20px;
  --body-text-20-regular-font-style: normal;
  --body-text-20-regular-font-weight: 400;
  --body-text-20-regular-letter-spacing: 0px;
  --body-text-20-regular-line-height: 30px;
  --body-title-bold-font-family: "Aeonik", Helvetica;
  --body-title-bold-font-size: 20px;
  --body-title-bold-font-style: normal;
  --body-title-bold-font-weight: 700;
  --body-title-bold-letter-spacing: 0px;
  --body-title-bold-line-height: 30px;
  --didital-media-page-image-1: rgba(217, 217, 217, 1);
  --digital-media-page-image-2: rgba(217, 217, 217, 1);
  --digital-media-page-image-3: rgba(217, 217, 217, 1);
  --error: rgba(255, 87, 51, 1);
  --gray-100: rgba(247, 247, 247, 1);
  --gray-200: rgba(233, 233, 233, 1);
  --gray-300: rgba(217, 217, 217, 1);
  --gray-400: rgba(191, 191, 191, 1);
  --gray-500: rgba(169, 169, 169, 1);
  --gray-600: rgba(140, 140, 140, 1);
  --greys-100: rgba(247, 247, 247, 1);
  --gray-100: rgba(247, 247, 247, 1);
  --gray-200: rgba(233, 233, 233, 1);
  --gray-300: rgba(217, 217, 217, 1);
  --gray-400: rgba(191, 191, 191, 1);
  --gray-500: rgba(169, 169, 169, 1);
  --gray-600: rgba(140, 140, 140, 1);
  --grit-website-black: rgba(30, 30, 30, 1);
  --grit-website-primary: #00bfa5;
  --grit-website-secondary: rgba(26, 35, 126, 1);
  --grit-website-white: rgba(255, 255, 255, 1);
  --heading-1-64-bold-font-family: "Roboto", Helvetica;
  --heading-1-64-bold-font-size: 64px;
  --heading-1-64-bold-font-style: normal;
  --heading-1-64-bold-font-weight: 700;
  --heading-1-64-bold-letter-spacing: 0px;
  --heading-1-64-bold-line-height: 100px;
  --heading-2-56-bold-font-family: "Roboto", Helvetica;
  --heading-2-56-bold-font-size: 56px;
  --heading-2-56-bold-font-style: normal;
  --heading-2-56-bold-font-weight: 700;
  --heading-2-56-bold-letter-spacing: 0px;
  --heading-2-56-bold-line-height: 80px;
  --heading-3-48-bold-font-family: "Roboto", Helvetica;
  --heading-3-48-bold-font-size: 48px;
  --heading-3-48-bold-font-style: normal;
  --heading-3-48-bold-font-weight: 700;
  --heading-3-48-bold-letter-spacing: 0px;
  --heading-3-48-bold-line-height: 75px;
  --heading-3-48-medium-font-family: "Roboto", Helvetica;
  --heading-3-48-medium-font-size: 48px;
  --heading-3-48-medium-font-style: normal;
  --heading-3-48-medium-font-weight: 700;
  --heading-3-48-medium-letter-spacing: 0px;
  --heading-3-48-medium-line-height: 75px;
  --heading-3-48-regular-font-family: "Roboto", Helvetica;
  --heading-3-48-regular-font-size: 48px;
  --heading-3-48-regular-font-style: normal;
  --heading-3-48-regular-font-weight: 700;
  --heading-3-48-regular-letter-spacing: 0px;
  --heading-3-48-regular-line-height: 75px;
  --heading-4-40-bold-font-family: "Roboto", Helvetica;
  --heading-4-40-bold-font-size: 40px;
  --heading-4-40-bold-font-style: normal;
  --heading-4-40-bold-font-weight: 700;
  --heading-4-40-bold-letter-spacing: 0px;
  --heading-4-40-bold-line-height: 60px;
  --heading-4-40-medium-font-family: "Roboto", Helvetica;
  --heading-4-40-medium-font-size: 40px;
  --heading-4-40-medium-font-style: normal;
  --heading-4-40-medium-font-weight: 500;
  --heading-4-40-medium-letter-spacing: 0px;
  --heading-4-40-medium-line-height: 60px;
  --heading-4-40-regular-font-family: "Roboto", Helvetica;
  --heading-4-40-regular-font-size: 40px;
  --heading-4-40-regular-font-style: normal;
  --heading-4-40-regular-font-weight: 400;
  --heading-4-40-regular-letter-spacing: 0px;
  --heading-4-40-regular-line-height: 60px;
  --heading-5-32-bold-font-family: "Roboto", Helvetica;
  --heading-5-32-bold-font-size: 32px;
  --heading-5-32-bold-font-style: normal;
  --heading-5-32-bold-font-weight: 700;
  --heading-5-32-bold-letter-spacing: 0px;
  --heading-5-32-bold-line-height: 40px;
  --heading-5-32-medium-font-family: "Roboto", Helvetica;
  --heading-5-32-medium-font-size: 32px;
  --heading-5-32-medium-font-style: normal;
  --heading-5-32-medium-font-weight: 500;
  --heading-5-32-medium-letter-spacing: 0px;
  --heading-5-32-medium-line-height: 52px;
  --heading-5-32-regular-font-family: "Roboto", Helvetica;
  --heading-5-32-regular-font-size: 32px;
  --heading-5-32-regular-font-style: normal;
  --heading-5-32-regular-font-weight: 400;
  --heading-5-32-regular-letter-spacing: 0px;
  --heading-5-32-regular-line-height: 58px;
  --heading-6-24-bold-font-family: "Roboto", Helvetica;
  --heading-6-24-bold-font-size: 24px;
  --heading-6-24-bold-font-style: normal;
  --heading-6-24-bold-font-weight: 700;
  --heading-6-24-bold-letter-spacing: 0px;
  --heading-6-24-bold-line-height: 42px;
  --heading-6-24-medium-font-family: "Roboto", Helvetica;
  --heading-6-24-medium-font-size: 24px;
  --heading-6-24-medium-font-style: normal;
  --heading-6-24-medium-font-weight: 500;
  --heading-6-24-medium-letter-spacing: 0px;
  --heading-6-24-medium-line-height: 42px;
  --heading-6-24-regular-font-family: "Roboto", Helvetica;
  --heading-6-24-regular-font-size: 24px;
  --heading-6-24-regular-font-style: normal;
  --heading-6-24-regular-font-weight: 400;
  --heading-6-24-regular-letter-spacing: 0px;
  --heading-6-24-regular-line-height: 48px;
  --information: rgba(39, 103, 220, 1);
  --marketing-solu-hero-image: rgba(244, 244, 244, 1);
  --primary-200: rgba(85, 212, 195, 1);
  --primary-300: rgba(170, 234, 225, 1);
  --primary-colour: rgba(0, 123, 255, 1);
  --primary-dark: rgba(0, 64, 55, 1);
  --secondary-colour: rgba(40, 167, 69, 1);
  --small-body-text-16-bold-font-family: "Roboto", Helvetica;
  --small-body-text-16-bold-font-size: 16px;
  --small-body-text-16-bold-font-style: normal;
  --small-body-text-16-bold-font-weight: 700;
  --small-body-text-16-bold-letter-spacing: 0px;
  --small-body-text-16-bold-line-height: 24px;
  --small-body-text-16-medium-font-family: "Roboto", Helvetica;
  --small-body-text-16-medium-font-size: 16px;
  --small-body-text-16-medium-font-style: normal;
  --small-body-text-16-medium-font-weight: 500;
  --small-body-text-16-medium-letter-spacing: 0px;
  --small-body-text-16-medium-line-height: 26px;
  --small-body-text-16-regular-font-family: "Roboto", Helvetica;
  --small-body-text-16-regular-font-size: 16px;
  --small-body-text-16-regular-font-style: normal;
  --small-body-text-16-regular-font-weight: 400;
  --small-body-text-16-regular-letter-spacing: 0.10000000149011612px;
  --small-body-text-16-regular-line-height: 28px;
  --smaller-tect-14-bold-font-family: "Roboto", Helvetica;
  --smaller-tect-14-bold-font-size: 14px;
  --smaller-tect-14-bold-font-style: normal;
  --smaller-tect-14-bold-font-weight: 700;
  --smaller-tect-14-bold-letter-spacing: 0px;
  --smaller-tect-14-bold-line-height: 21px;
  --smaller-text-14-meduim-font-family: "Roboto", Helvetica;
  --smaller-text-14-meduim-font-size: 14px;
  --smaller-text-14-meduim-font-style: normal;
  --smaller-text-14-meduim-font-weight: 500;
  --smaller-text-14-meduim-letter-spacing: 0px;
  --smaller-text-14-meduim-line-height: 21px;
  --smaller-text-14-regular-font-family: "Roboto", Helvetica;
  --smaller-text-14-regular-font-size: 14px;
  --smaller-text-14-regular-font-style: normal;
  --smaller-text-14-regular-font-weight: 400;
  --smaller-text-14-regular-letter-spacing: 0px;
  --smaller-text-14-regular-line-height: 24px;
  --success: rgba(59, 144, 6, 1);
  --text-regular-link-font-family: "Roboto", Helvetica;
  --text-regular-link-font-size: 16px;
  --text-regular-link-font-style: normal;
  --text-regular-link-font-weight: 400;
  --text-regular-link-letter-spacing: 0px;
  --text-regular-link-line-height: 150%;
  --variable-collection-grit-website-BLACK: rgba(30, 30, 30, 1);
  --variable-collection-white: rgba(255, 255, 255, 1);
  --warning: rgba(238, 163, 24, 1);
}
